/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyDxjUfhJjZVFQYuBMlC3TYXwJEAPSNpyiI",
  "appId": "1:514547463570:web:339168d8583aba395c3d4b",
  "authDomain": "schooldog-i-macon-ga.firebaseapp.com",
  "measurementId": "G-Q86H019KEM",
  "messagingSenderId": "514547463570",
  "project": "schooldog-i-macon-ga",
  "projectId": "schooldog-i-macon-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-macon-ga.appspot.com"
}
